import { environment } from 'src/environments/environment';

const URL_SERVICIOS_SALESROOM = environment.urlServicios;
const URL_PROPERTY_ASOCIATION = environment.SalesRoomUrlServicios;
const URL_SERVICIOS_NOVELTIES = environment.urlNovelties;
const URL_SERVICIOS_INVENTORY = environment.urlInventory;
const URL_SERVICIOS_BUSINESS_REPORTS = environment.urlReports;
const URL_HELP_CENTER = environment.urlHelpCenter;
const URL_DIGITAL_BONDING = environment.urlDigitalBonding;

export class Endpoint {
  constructor(public baseUrl: string, public path: string, public authenticated: boolean = true) {}
}

export const ENDPOINTS = {
  // URL_SERVICIOS_SALESROOM
  getHeadRelationship: new Endpoint(URL_SERVICIOS_SALESROOM, 'head-relationship'),
  getAuxiliaryBackoffice: new Endpoint(URL_SERVICIOS_SALESROOM, 'auxiliary-backoffice'),
  getFindAllTypeFile: new Endpoint(URL_SERVICIOS_SALESROOM, 'findalltypefile'),
  findIdentificationTypes: new Endpoint(URL_SERVICIOS_SALESROOM, 'typeid'),
  findProjectsUser: new Endpoint(URL_SERVICIOS_SALESROOM, 'findprojectsuser'),
  getEconomicActivities: new Endpoint(URL_SERVICIOS_SALESROOM, 'economic-activity'),

  // URL_PROPERTY_ASOCIATION
  changeStatusRequestManaged: new Endpoint(
    URL_PROPERTY_ASOCIATION,
    'request-property-unit/change-status/managed/{id}',
  ),
  findClientsOfRequest: new Endpoint(URL_PROPERTY_ASOCIATION, 'request-property-unit-customers/advisor'),
  getDuplicateRequests: new Endpoint(URL_PROPERTY_ASOCIATION, 'request-property-unit/duplicaterequest/{id}'),
  getFileToManage: new Endpoint(URL_PROPERTY_ASOCIATION, 'request-property-unit/files/getfiletomanage'),
  getRequestByNoveltiesIdFromBackoffice: new Endpoint(
    URL_PROPERTY_ASOCIATION,
    'request-property-unit/back-office-novelties-id/{id}',
  ),
  getRequestByNoveltiesIdFromAdvisor: new Endpoint(
    URL_PROPERTY_ASOCIATION,
    'request-property-unit/advisor-novelties-id/{id}',
  ),
  saveRequestManaged: new Endpoint(URL_PROPERTY_ASOCIATION, 'request-property-unit/save-managed'),
  updateEconomicActivity: new Endpoint(
    URL_PROPERTY_ASOCIATION,
    'request-property-unit/advisor/economic-activity',
  ),
  requestsAdp: new Endpoint(URL_PROPERTY_ASOCIATION, 'requests-adp'),
  commentsSdv: new Endpoint(URL_PROPERTY_ASOCIATION, 'commentssdv'),

  // URL_SERVICIOS_NOVELTIES
  noveltiesFromAdvisor: new Endpoint(URL_SERVICIOS_NOVELTIES, 'novelties/advisor'),
  statusNovelty: new Endpoint(URL_SERVICIOS_NOVELTIES, 'novelties/change-status'),
  noveltiesFromBackoffice: new Endpoint(URL_SERVICIOS_NOVELTIES, 'novelties'),
  noveltyTypesFindAll: new Endpoint(URL_SERVICIOS_NOVELTIES, 'novelty-types'),
  noveltyStatusFindAll: new Endpoint(URL_SERVICIOS_NOVELTIES, 'novelty-statuses'),
  noveltyTypesFileFindAll: new Endpoint(URL_SERVICIOS_NOVELTIES, 'novelty-type-file/{noveltyTypeId}'),
  sendNoveltyToProcess: new Endpoint(URL_SERVICIOS_NOVELTIES, 'novelties/process'),
  sendNoveltyToSave: new Endpoint(URL_SERVICIOS_NOVELTIES, 'novelties/save'),
  findNoveltyById: new Endpoint(URL_SERVICIOS_NOVELTIES, 'novelties/advisor/{noveltyId}'),
  findNoveltyHistory: new Endpoint(URL_SERVICIOS_NOVELTIES, 'novelty-history/advisor/{novelty-id}'),
  findNoveltyHistoryFromBackoffice: new Endpoint(URL_SERVICIOS_NOVELTIES, 'novelty-history/{novelty-id}'),
  changeStatusNoveltyManaged: new Endpoint(URL_SERVICIOS_NOVELTIES, 'novelties/{id}/managed'),
  findNoveltyByIdFromBackoffice: new Endpoint(URL_SERVICIOS_NOVELTIES, 'novelties/{noveltyId}'),
  uploadNoveltyFile: new Endpoint(URL_SERVICIOS_NOVELTIES, 'noveltyfile/upload'),
  deleteNoveltyFile: new Endpoint(URL_SERVICIOS_NOVELTIES, 'noveltyfile/delete/{id}'),
  viewNoveltyAdvisorFile: new Endpoint(URL_SERVICIOS_NOVELTIES, 'noveltyfile/advisor/{id}'),
  filesUploadedforNoveltyAdvisor: new Endpoint(
    URL_SERVICIOS_NOVELTIES,
    'noveltyfile/files-for-novelty/advisor',
  ),
  archivedNovelty: new Endpoint(URL_SERVICIOS_NOVELTIES, 'novelties/{id}/archived'),
  getNoveltyReturnCauses: new Endpoint(
    URL_SERVICIOS_NOVELTIES,
    'response-causes/return/{idNoveltyType}/{idTypology}/{representative}',
  ),
  approveNovelty: new Endpoint(URL_SERVICIOS_NOVELTIES, 'novelties/{noveltyId}/approve'),
  approveTranslator: new Endpoint(URL_SERVICIOS_NOVELTIES, 'novelties/{noveltyId}/approveTranslator'),
  returnNovelty: new Endpoint(URL_SERVICIOS_NOVELTIES, 'novelties/{noveltyId}/return'),
  viewNoveltyFileFromBackOffice: new Endpoint(URL_SERVICIOS_NOVELTIES, 'noveltyfile/{id}'),
  filesUploadedforNoveltyBackoffice: new Endpoint(
    URL_SERVICIOS_NOVELTIES,
    'noveltyfile/files-for-novelty/{id}',
  ),
  saveNoveltyManaged: new Endpoint(URL_SERVICIOS_NOVELTIES, 'novelties/save-managed'),
  noveltiesAdp: new Endpoint(URL_SERVICIOS_NOVELTIES, 'novelties-adp'),
  updateAgreement: new Endpoint(URL_SERVICIOS_NOVELTIES, 'novelties/update-agreement'),
  saveInventoryNovelties: new Endpoint(URL_SERVICIOS_NOVELTIES, 'inventory-novelties/save'),
  deleteInventoryNovelties: new Endpoint(URL_SERVICIOS_NOVELTIES, 'inventory-novelties/delete/{id}'),
  getDocumentsByCustomer: new Endpoint(
    URL_SERVICIOS_NOVELTIES,
    'noveltyfile/files-for-novelty-formalization',
  ),
  saveNoveltyRevenue: new Endpoint(URL_SERVICIOS_NOVELTIES, 'novelties/save-novelty-revenue'),
  listNoveltiesRevenue: new Endpoint(
    URL_SERVICIOS_NOVELTIES,
    'novelty-types/list-novelties-revenue/{noveltyId}',
  ),
  saveUnitsTranslator: new Endpoint(
    URL_SERVICIOS_NOVELTIES,
    'novelties-new/create-novelties-unit-translator/{codeProject}',
  ),
  deleteUnitsTranslator: new Endpoint(
    URL_SERVICIOS_NOVELTIES,
    'novelties-new/delete-novelties-unit-translator/{codeProject}',
  ),
  findUnitsNoveltyTranslator: new Endpoint(
    URL_SERVICIOS_NOVELTIES,
    'novelties-new/find-novelties-unit-translator/{noveltiesId}',
  ),
  approveNoveltyTranslator: new Endpoint(URL_SERVICIOS_NOVELTIES, 'novelties/{noveltyId}/approveTranslator'),
  returnNoveltyTranslator: new Endpoint(URL_SERVICIOS_NOVELTIES, 'novelties/{noveltyId}/returnTranslator'),
  getUnitsByReference: new Endpoint(
    URL_SERVICIOS_NOVELTIES,
    'novelties-new/find-all-by-reference/{reference}',
  ),
  validRequestAprovedOtroSi: new Endpoint(
    URL_SERVICIOS_NOVELTIES,
    'novelties/novelties-reference/{reference}',
  ),

  // WITHDRAWAL
  createWithdrawalWithoutRefund: new Endpoint(
    URL_SERVICIOS_NOVELTIES,
    'withdrawal/create-withdrawal-without-refund',
  ),
  detailWithdrawalWithoutRefund: new Endpoint(
    URL_SERVICIOS_NOVELTIES,
    'withdrawal/advisor/unilateral/{withdrawalId}',
  ),
  detailBackofficeWithdrawalWithoutRefund: new Endpoint(
    URL_SERVICIOS_NOVELTIES,
    'withdrawal/unilateral/{withdrawalId}',
  ),
  saveWithdrawalWithoutRefund: new Endpoint(URL_SERVICIOS_NOVELTIES, 'withdrawal/withoutrefund/save'),
  processWithdrawalWithoutRefund: new Endpoint(URL_SERVICIOS_NOVELTIES, 'withdrawal/withoutrefund/process'),
  getDocumentsWithdrawal: new Endpoint(URL_SERVICIOS_NOVELTIES, 'withdrawal/documents'),
  uploadFileacquirer: new Endpoint(URL_SERVICIOS_NOVELTIES, 'withdrawalfile/withoutrefund/uploadacquirer'),
  detailWithdrawalUnilateralWithoutRecourse: new Endpoint(
    URL_SERVICIOS_NOVELTIES,
    'withdrawal/unilateral/{withdrawalId}',
  ),
  detailWithdrawalWithRecourseBackoffice: new Endpoint(
    URL_SERVICIOS_NOVELTIES,
    'withdrawal/detail-withdrawal-with-resources/{id}',
  ),
  getNoveltyCausesWithdrawal: new Endpoint(
    URL_SERVICIOS_NOVELTIES,
    'response-causes/return/{idStatusNovelty}/{idNoveltyType}',
  ),
  getDocumentsRol: new Endpoint(URL_SERVICIOS_NOVELTIES, 'withdrawal/get-documents-rol'),
  getDocumentsRolBackoffice: new Endpoint(URL_SERVICIOS_NOVELTIES, 'withdrawal/get-documents-rol-backoffice'),
  deleteNoveltyAcquirerIndexedFile: new Endpoint(
    URL_SERVICIOS_NOVELTIES,
    'withdrawalfile/withoutrefund/delete-indexed-file-acquirer/{noveltyFileId}',
  ),
  getDocumentsAcquirer: new Endpoint(
    URL_SERVICIOS_NOVELTIES,
    'withdrawalfile/withoutrefund/documentacquirer/{noveltyId}',
  ),
  detailWithdrawalWithRecourse: new Endpoint(
    URL_SERVICIOS_NOVELTIES,
    'withdrawal/advisor/with-recourse/{withdrawalId}',
  ),
  saveCheckOrAccount: new Endpoint(URL_SERVICIOS_NOVELTIES, 'withdrawal/save-check-or-account/{id}'),
  deleteAccount: new Endpoint(URL_SERVICIOS_NOVELTIES, 'withdrawal/delete-account-destiny/{id}'),
  generateWithdrawal: new Endpoint(URL_SERVICIOS_NOVELTIES, 'withdrawal/withdrawal-with-resources'),

  // URL_SERVICIOS_SAGA
  getTypeAccount: new Endpoint(URL_SERVICIOS_NOVELTIES, 'third-business-information/accounts-types'),
  getBanks: new Endpoint(URL_SERVICIOS_NOVELTIES, 'third-business-information/banks-entity'),

  // URL_SERVICIOS_INVENTORY
  getReferenceInventory: new Endpoint(URL_SERVICIOS_INVENTORY, 'get-data-reference'),
  findInventoryRequestsFromBackoffice: new Endpoint(URL_SERVICIOS_INVENTORY, 'inventory'),
  findInventoryRequests: new Endpoint(URL_SERVICIOS_INVENTORY, 'inventory/advisor'),
  getAllPdfReference: new Endpoint(URL_SERVICIOS_INVENTORY, 'inventory/pdfs/advisor'),
  getRequestsWithReferences: new Endpoint(URL_SERVICIOS_INVENTORY, 'get-pdf-references'),
  getRequestsWithReferencesAll: new Endpoint(URL_SERVICIOS_INVENTORY, 'get-pdf-references-all'),
  getDetailPdfReference: new Endpoint(URL_SERVICIOS_INVENTORY, 'get-detail-pdf-reference/{requestId}'),
  generatePdfReference: new Endpoint(URL_SERVICIOS_INVENTORY, 'generate-pdf-reference'),
  uploadInventory: new Endpoint(URL_SERVICIOS_INVENTORY, 'upload-file/project/upload'),
  processInventory: new Endpoint(URL_SERVICIOS_INVENTORY, 'upload-file/project/process'),
  deleteInventory: new Endpoint(URL_SERVICIOS_INVENTORY, 'upload-file/project/{inventoryId}/delete'),
  detailInventory: new Endpoint(URL_SERVICIOS_INVENTORY, 'inventory/detail/{projectId}/{inventoryId}'),
  adpDetailInventory: new Endpoint(URL_SERVICIOS_INVENTORY, 'inventory/adp/detail/{inventoryId}'),
  getHistoryInventoryAdvisor: new Endpoint(URL_SERVICIOS_INVENTORY, 'history/advisor/{inventoryFileId}'),
  getHistoryInventoryFromBack: new Endpoint(URL_SERVICIOS_INVENTORY, 'history/{inventoryFileId}'),
  downloadInventoryAdvisor: new Endpoint(URL_SERVICIOS_INVENTORY, 'download-file/advisor/{inventoryFileId}'),
  downloadInventoryBackoffice: new Endpoint(URL_SERVICIOS_INVENTORY, 'download-file/{inventoryFileId}'),
  downloadInventoryExcelAdvisor: new Endpoint(
    URL_SERVICIOS_INVENTORY,
    'download-file/advisor/approve/{codeProject}/{batch}',
  ),
  categoriesInventory: new Endpoint(URL_SERVICIOS_INVENTORY, 'catalog/categories-list/{codeProject}'),
  subStagesInventory: new Endpoint(URL_SERVICIOS_INVENTORY, 'catalog/sub-stage-list/{codeProject}'),
  loadCausesResponseInventory: new Endpoint(URL_SERVICIOS_INVENTORY, 'response-causes/inventory'),
  returnInventory: new Endpoint(URL_SERVICIOS_INVENTORY, 'change-status/{inventoryFileId}'),
  findAllInventory: new Endpoint(URL_SERVICIOS_INVENTORY, 'catalog/find-all-inventory'),
  findUnistInventoryApproved: new Endpoint(
    URL_SERVICIOS_INVENTORY,
    'catalog/find-number-units/{codeProject}/{batch}',
  ),
  approveInventory: new Endpoint(URL_SERVICIOS_INVENTORY, 'change-status/approve/{inventoryFileId}'),
  generatePdf: new Endpoint(URL_SERVICIOS_INVENTORY, 'get-reference/{requestId}'),
  associateReference: new Endpoint(
    URL_SERVICIOS_INVENTORY,
    'catalog/{requestId}/{reference}/associate-reference',
  ),
  approveMessageInventory: new Endpoint(
    URL_SERVICIOS_INVENTORY,
    'inventory/approve-message-inventory/{inventoryFileId}',
  ),
  findAllUnistInventory: new Endpoint(URL_SERVICIOS_INVENTORY, 'catalog/find-all-number-inventory-units'),
  findAllUnistInventoryCombined: new Endpoint(
    URL_SERVICIOS_INVENTORY,
    'catalog/find-all-number-inventory-units-combined',
  ),
  findInventoryCategory: new Endpoint(URL_SERVICIOS_INVENTORY, 'catalog/find-inventory-category'),
  sendInventoryRequestsSave: new Endpoint(URL_SERVICIOS_INVENTORY, 'inventory-requests/save'),
  getInventoryRequests: new Endpoint(
    URL_SERVICIOS_INVENTORY,
    'inventory-requests/get-inventory-requests/{idRequests}',
  ),
  deleteInventoryRequests: new Endpoint(
    URL_SERVICIOS_INVENTORY,
    'inventory-requests/delete-inventory-requests',
  ),
  getInventoryRequestsPackage: new Endpoint(
    URL_SERVICIOS_INVENTORY,
    'inventory-requests/get-inventory-requests-package/{idRequests}',
  ),
  findProjectsNewsUser: new Endpoint(URL_SERVICIOS_INVENTORY, 'inventory/find-all-projects-news'),
  downloadInventoryAns: new Endpoint(URL_SERVICIOS_INVENTORY, 'view-inventory-report-ans/download'),
  reportRequestReference: new Endpoint(
    URL_SERVICIOS_INVENTORY,
    'change-status/report-request/reference/{idRequest}',
  ),
  vacantUnitNumber: new Endpoint(
    URL_SERVICIOS_INVENTORY,
    'change-status/release-real-estate-unit/{idRequest}',
  ),
  releasePropertyUnitRequests: new Endpoint(
    URL_SERVICIOS_INVENTORY,
    'change-status/release-property-unit-requests',
  ),
  getUnitsPackage: new Endpoint(URL_SERVICIOS_INVENTORY, 'inventory-requests/get-units-package-project/unit'),
  inventoryAcquirer: new Endpoint(URL_SERVICIOS_INVENTORY, 'inventory-acquirer/save-all'),
  enableReference: new Endpoint(URL_SERVICIOS_INVENTORY, 'catalog/novelties/enable-reference'),
  disableReference: new Endpoint(URL_SERVICIOS_INVENTORY, 'catalog/novelties/disable-reference'),
  getStagesByCodeProject: new Endpoint(URL_SERVICIOS_INVENTORY, 'get-stages-project/{projectId}'),

  //Novedades de inventario
  getAllTypologyInventory: new Endpoint(URL_SERVICIOS_INVENTORY, 'inventory/get-all-tipology-inventory'),
  getInactiveUnitsInventory: new Endpoint(
    URL_SERVICIOS_INVENTORY,
    'upload-file/get-inactive-units-inventory/{id}',
  ),

  //URL SERVICIOS BUSINESS-REPORTS
  reportReleaseUnits: new Endpoint(URL_SERVICIOS_BUSINESS_REPORTS, 'reports/release-units'),

  //URL SERVICIOS INMOBILIARIO COMPLETO
  validateMigrationStage: new Endpoint(URL_SERVICIOS_INVENTORY, 'validate-migration-number-stage'),
  getListTypeProject: new Endpoint(URL_SERVICIOS_SALESROOM, 'type-projects'),
  getMigrateStages: new Endpoint(
    URL_SERVICIOS_INVENTORY,
    'get-migrate-stage/{projectIdPresaleCode}/{codeProject}',
  ),
  getDetailProjectByCode: new Endpoint(URL_SERVICIOS_SALESROOM, 'find-project-detail/{codeProject}'),
  getDetailProjectById: new Endpoint(URL_SERVICIOS_SALESROOM, 'projectdetail/{id}'),
  migrateStageSelected: new Endpoint(URL_SERVICIOS_INVENTORY, 'migrate-stages'),
  reportDownloadReference: new Endpoint(
    URL_SERVICIOS_INVENTORY,
    'catalog/{requestId}/{reference}/generar-reporte-aprobado',
  ),
  getClientsFormalization: new Endpoint(
    URL_PROPERTY_ASOCIATION,
    'customer-formalization/reference/{numReference}/novelty/{noveltyId}',
  ),
  getRequestOriginalByReference: new Endpoint(
    URL_PROPERTY_ASOCIATION,
    'request-property-unit/advisor/reference/{numReference}',
  ),
  saveClientFormalization: new Endpoint(URL_PROPERTY_ASOCIATION, 'customer-formalization'),
  filesforFormalizationAdvisor: new Endpoint(
    URL_SERVICIOS_NOVELTIES,
    'noveltyfile/files-for-novelty-formalization',
  ),
  //URL HELP CENTER
  //notification
  createNotification: new Endpoint(URL_HELP_CENTER, 'notifications/create'),
  allNotificationAdmin: new Endpoint(URL_HELP_CENTER, 'notifications/list-notification-admin'),
  alladvisornotification: new Endpoint(URL_HELP_CENTER, 'notifications/list-notification-advisor'),
  allTypesNotification: new Endpoint(URL_HELP_CENTER, 'notification-types'),
  allStatusNotification: new Endpoint(URL_HELP_CENTER, 'notification-status'),
  documentNotification: new Endpoint(URL_HELP_CENTER, 'notifications/get-document-notification/{id}'),
  validateDaysNotification: new Endpoint(URL_HELP_CENTER, 'notifications/get-validate-range-date'),
  inactiveNotification: new Endpoint(URL_HELP_CENTER, 'notifications/get-inactive-notification/{id}'),
  updateNotification: new Endpoint(URL_HELP_CENTER, 'notifications/update-notification'),
  detailNotification: new Endpoint(URL_HELP_CENTER, 'notifications/get-detail-notification/{id}'),
  uploadDocumentNotification: new Endpoint(URL_HELP_CENTER, 'notifications/upload-document-notification'),

  //news
  createNews: new Endpoint(URL_HELP_CENTER, 'news/create'),
  allNewsAdmin: new Endpoint(URL_HELP_CENTER, 'news/list-news-admin'),
  alladvisornews: new Endpoint(URL_HELP_CENTER, 'news/list-news-advisor'),
  allNewsImages: new Endpoint(URL_HELP_CENTER, 'newsimage/list-image-news'),
  imageNewsId: new Endpoint(URL_HELP_CENTER, 'newsimage/admin-image/{id}'),
  documentNews: new Endpoint(URL_HELP_CENTER, 'news/get-document-news/{id}'),
  detailNews: new Endpoint(URL_HELP_CENTER, 'news/get-detail-new/{id}'),
  inactiveNews: new Endpoint(URL_HELP_CENTER, 'news/get-inactive-news/{id}'),
  updateNews: new Endpoint(URL_HELP_CENTER, 'news/update-news'),
  uploadDocumentNews: new Endpoint(URL_HELP_CENTER, 'news/upload-document-news'),

  //URL_DIGITAL_BONDING
  signerSearch: new Endpoint(URL_DIGITAL_BONDING, 'trust-signer/find-code-project/{codeProject}'),
  signerUpdate: new Endpoint(URL_DIGITAL_BONDING, 'signer/signer-update'),
  signerType: new Endpoint(URL_DIGITAL_BONDING, 'signer/signer-type/{signerTypeId}'),
  signerRequest: new Endpoint(URL_DIGITAL_BONDING, 'signer/signer-request/{requestId}'),
  signerNovelty: new Endpoint(URL_DIGITAL_BONDING, 'signer/signer-novelty/{noveltyId}'),
  signerSave: new Endpoint(URL_DIGITAL_BONDING, 'signer/signer-save'),
  signerFind: new Endpoint(URL_DIGITAL_BONDING, 'signer/signer-find/{id}'),
  trustorRequest: new Endpoint(URL_DIGITAL_BONDING, 'signer/signer-request-type/{requestId}'),
};
