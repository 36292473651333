const HOST = 'https://negocios-inversion-ext-dev.apps.ambientesbc.com/saladeventas';
export const environment = {
  production: false,
  urlServicios: HOST+'/sala-ventas',
  SalesRoomUrlServicios: HOST+'/sales-room',
  urlNovelties: HOST + '/novelties',
  urlInventory: HOST + '/inventory',
  urlReports: HOST + '/business-reports',
  urlHelpCenter: HOST + '/help-center',
  urlDigitalBonding: HOST + '/digital-bonding',
  env : 'Desarrollo',
  siteKey : '6Ld48qcbAAAAACX0WH5BJwTonTCSpiNgZaP3kdMC',
  captchaEnabled : 'true',
  encryptSecretKey : '6Le_E8YUAAAAAPr4eDyEXCifFlwOX8qBbBTfmMO3',
  clientId: '331b71ff-7349-4130-894a-e22535ed057c',
  redirectUri: 'https://salaventas-dev.apps.ambientesbc.com/admin/',
  redirectUriLogout: 'https://salaventas-dev.apps.ambientesbc.com/',
  authority: 'https://login.microsoftonline.com/428f4e2e-13bf-4884-b364-02ef9af41a1d',
  tagkey:'68c4283db8074b12df1660b31c0220a9',
  enableNewFunctionalities: 'true',
  messageInventorySuccess: 'Tu inventario se encuentra aprobado, desde este momento puedes comenzar la venta de tu proyecto',
  linkPersonaNatural: 'https://www.bancolombia.com/wcm/connect/e10cd0d0-d0fb-434f-a89f-e4b0649a8c25/Conocimiento+del+Cliente+PN.pdf?MOD=AJPERES',
  linkPersonaJuridica: 'https://www.bancolombia.com/wcm/connect/af6b355f-bf2a-4d87-a42a-ad551a348408/Formato+Conocimiento+Cliente+PJ.pdf?MOD=AJPERES',
  linkSociosAccionistas: 'http://www.bancolombia.com/wcm/connect/37068725-d156-4e5e-8714-d8cfa2d73b7c/Conocimiento+de+Socios+Accionistas.xlsm?MOD=AJPERES',
  linkCompradorBilateral: 'https://www.bancolombia.com/wcm/connect/83658515-5449-4a32-94ee-98a447bcc9b1/Carta_del+Comprador+_-_Bilateral.docx?MOD=AJPERES',
  linkConstructoraBilateral: 'https://www.bancolombia.com/wcm/connect/30043f00-b072-4fef-a35f-82ad6fcf302f/Carta_Constructora_-_Bilateral.docx?MOD=AJPERES',
  linkConstructoraUnilateral: 'https://www.bancolombia.com/wcm/connect/2fb2de5e-d6be-45cf-9835-6f4f8f61605f/Carta_Constructora_-_Unilateral.docx?MOD=AJPERES',
  linkPdfMessageVinculation: 'https://fiduciaria.grupobancolombia.com/productos-servicios/negocios-fiduciarios/preventas',
  linkCartaBilateral: 'https://fiduciaria.grupobancolombia.com/wcm/connect/73a7701f-36c6-47e5-b96a-e154c6066a40/Carta_desistimiento_unificado.docx?MOD=AJPERES',
  idProyectoFirmante: '2913,6633',
  proyectoFirmante: '8768686876,58885'
};

